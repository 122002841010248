import PropTypes from 'prop-types';
import classnames from 'classnames';
import OtpInput from 'react-otp-input';
import React, { Component } from 'react';
import Countdown from 'react-countdown-now';
import { NotificationManager } from 'react-notifications';

export default class OtpModal extends Component {
    
    state = {
        local_otp: '',
    }

    componentDidUpdate(prevProps){
        const {is_active} = this.props;
        if(prevProps.is_active !== is_active && is_active === false){
            this.setState({local_otp: ''});
        }
    }

    onOtpChange = (otp) => {
        this.setState({local_otp: otp});
        // this.props.updateOtp(otp);
    }

    onOtpSubmit = (e) => {
        e.preventDefault();
        const {local_otp} = this.state;
        this.props.onOtpSubmit(local_otp);
    }

    onCloseLocal = () => {
        this.setState({local_otp: ''});
        this.props.onClose();
    }

    // Renderer callback with condition
     renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            this.props.onClose();
            NotificationManager.info('Oops you have exhausted all the attempts to verify your identity. Please try again.');
            return null;
        } else {
            // Render a countdown
            return <span>{minutes} minute(s) , {seconds} second(s) </span>;
        }
    };

    render() {
        const { local_otp } = this.state;
        const { validating, is_active, sendOTP, resend_flag, attempt_number , date_now } = this.props;
        return (
            <div className={classnames('modal', {"is-active" : is_active})}>
                <div className="modal-background"></div>
                <div className="modal-content card border-5">
                    <div className="card-content">
                        <p className="is-size-6 has-text-black">Please enter the One Time Password (OTP) to verify your account</p>
                        <br/>
                        {is_active ? 
                            <div className="has-text-centered pd-bottom-10">
                                <Countdown date={ date_now + 300000} renderer={this.renderer}/> 
                            </div>
                        : null}
                        <form onSubmit={this.onOtpSubmit}>
                            <div className="field has-text-centered">
                                <OtpInput
                                    onChange={this.onOtpChange}
                                    value={local_otp}
                                    numInputs={6}
                                    separator={<span>-</span>}
                                    shouldAutoFocus={true}
                                    inputStyle={'otp-input'}
                                    containerStyle={'flex-center'}
                                />
                                <p className="is-size-7 mg-top-10">{attempt_number} / 3 Attempts</p>
                                {!resend_flag && attempt_number !== 3 ? <button type="button" onClick={sendOTP} className="button is-link is-inverted is-small is-hidden-mobile" id="cancel-forget-password">
                                    Resend OTP?
                                </button> : null}
                            </div>
                            <div className="field">
                                <p className="control has-text-right forget-buttons">
                                    <button type="button" onClick={this.onCloseLocal} className="button is-white inverted-button is-hidden-mobile" id="cancel-forget-password">
                                        Cancel
                                    </button>
                                    &nbsp;
                                    <button type="submit" id="reset-password" className={classnames('button is-link', {'is-loading': validating})} disabled={local_otp.length !== 6}>
                                        Submit
                                    </button>
                                </p>
                            </div>
                        </form>
                    </div> 
                </div>
                <button onClick={this.onCloseLocal} className="modal-close is-large is-hidden-tablet" aria-label="close"></button>
            </div>
        )
    }
}

// Porptypes
// ---------
OtpModal.propTypes = {
    is_active: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onOtpSubmit: PropTypes.func.isRequired,
    validating: PropTypes.bool.isRequired,
    otp: PropTypes.string.isRequired,
    sendOTP: PropTypes.func.isRequired,
    attempt_number: PropTypes.number.isRequired,
    resend_flag: PropTypes.bool.isRequired
};

// default props
// -------------
OtpModal.defaultProps = {
    is_active: false,
    validating: false
};

