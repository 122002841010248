import React, {Component} from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import Anonymous from "../components/anonymousbat/AnonymousBat";
import { navigate } from "gatsby";

export default class AnonymousBat extends Component {

  componentDidMount(){
    if(true){
      navigate('/login/chat/anonymous')
    }
  }
  render() {
      return (
        <Layout mode={true} sticky_footer={false}>
      </Layout>
      );
  }
}